<template>
  <div class="content-page">
    <div class="main">
      <div class="loading" v-loading="loading" element-loading-text="拼命加载中"></div>
      <vue-markdown :source="contentText"></vue-markdown>
    </div>
  </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import { getYishuCal } from "@/api"
export default {
  name:"content-page",
  components:{
    VueMarkdown
  },
  data(){
    return {
      contentText:"",
      loading:false
    }
  },
  created(){
    this.queryData()
  },
  methods:{
    queryData(){
      let params = this.$route.query.time
      this.loading = true
      getYishuCal(params).then((res)=>{
        if(res.code == "200") {
          this.loading = false
          this.contentText = res.result
        }else{
          this.loading = false
          this.contentText = "暂无数据"
        }
      })
    }
  }
}
</script>
<style scoped>
.content-page {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/youguan/banner2.jpg");
  background-size: cover;
}
.main {
  overflow-x: auto;
  color: #fff;
  padding: 20px;
  text-align: center;
  width: 100%;
}
.loading {
  width: 100%;
  top: 48%;
}
</style>